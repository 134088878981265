import {
  Box,
  Theme,
  ThemeProvider,
  createTheme,
  useTheme,
  CircularProgress,
  Typography,
} from "@mui/material";
import { lazy, useEffect, useState } from "react";
import { Route, useParams, useRouteMatch } from "react-router-dom";
import { API_SITE_URL } from "config/environment";
import { useTranslation } from "react-i18next";
import { CustomImagesContext } from "context";
import fetchCustomAssets from "helpers/fetchCustomAssets";
const TagDispenserHome = lazy(() => import("./Home"));
const TagDispenserGetTag = lazy(() => import("./GetTag"));
const TagDispenserFinishFlow = lazy(() => import("./FinishFlow"));
const TagDispenserCodeAssociation = lazy(() => import("./CodeAssociation"));
const TagDispenserRoleSelection = lazy(() => import("./RoleSelection"));

type CustomTheme = {
  customizations: {
    muiTheme?: Theme & { overrrides?: any };
  };
};

const DispenserRoutes: React.FC = () => {
  const baseTheme: any = useTheme();
  const [theme, setTheme] = useState(baseTheme);
  const [images, setImages] = useState<Record<string, any>>({});
  const [lotties, setLotties] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation("TagDispenser");

  const { clientID } = useParams<{ clientID: string }>();

  const getCustomizations = async () => {
    try {
      const response = await fetch(
        `${API_SITE_URL}/getAppCustomizations?client=${clientID}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const customTheme: Theme = await response.json();
      return customTheme;
    } catch (error) {
      console.error("Error fetching custom theme:", error);
      return {};
    }
  };
  async function getMergedTheme() {
    const customTheme: CustomTheme | any = await getCustomizations();
    // If Error in fetch merge empty object
    if (!Object.keys(customTheme).length)
    return createTheme(baseTheme, customTheme);
    if (!Object.keys(customTheme.customizations).length) throw Error
    // Load custom assets in provider context
    const { images, lotties } = customTheme.assets;

    const { images: customImages, lotties: customLotties } =
      await fetchCustomAssets(
        images,
        lotties,
        customTheme.prefix,
        customTheme.logo_url
      );
    setImages(customImages);
    setLotties(customLotties);
    // Generate style tag for every font in override
    if (
      customTheme?.customizations?.muiTheme?.overrides &&
      customTheme?.customizations?.muiTheme?.overrides.MuiCssBaseline[
        "@global"
      ]["@font-face"].length
    ) {
      const styleTag = document.createElement("style");
      let content = "";
      for (const font of customTheme.customizations.muiTheme.overrides
        .MuiCssBaseline["@global"]["@font-face"]) {
        content = content.concat(
          `@font-face {font-family: ${font.fontFamily}; src: ${font.src}; font-style: ${font.fontStyle}};`
        );
      }
      styleTag.textContent = content;
      document.head.appendChild(styleTag);
    }
    return createTheme(customTheme?.customizations.muiTheme);
  }

  useEffect(() => {
    setLoading(true);
    getMergedTheme()
      .then((mergedTheme) => setTheme(mergedTheme))
      .catch(() => setLoading(false))
      .finally(() => setLoading(false));
  }, []);

  let { path } = useRouteMatch();
  if (loading)
    return (
      <Box
        sx={{
          display: "grid",
          height: "100svh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box>
          <CircularProgress size={80} />
          <Typography variant="body1">{t("Main.loading")}</Typography>
        </Box>
      </Box>
    );

  return (
    <ThemeProvider theme={theme}>
      <CustomImagesContext.Provider
        value={{
          images,
          lotties,
        }}
      >
        <Route exact path={path} component={TagDispenserHome} />
        <Route
          exact
          path={`${path}/code-association`}
          component={TagDispenserCodeAssociation}
        />
        <Route exact path={`${path}/get-tag`} component={TagDispenserGetTag} />
        <Route
          exact
          path={`${path}/finish`}
          component={TagDispenserFinishFlow}
        />
        <Route
          exact
          path={`${path}/role`}
          component={TagDispenserRoleSelection}
        />
      </CustomImagesContext.Provider>
    </ThemeProvider>
  );
};

export default DispenserRoutes;
