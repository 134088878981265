import { useLocation } from "react-router-dom";
import { Typography, useTheme } from "@mui/material";
import { useTranslation, Trans } from "react-i18next";
import Lottie from "react-lottie-player";

import { Container, Title } from "../components";
import HeroBannerLottieSrc from "./mail.json";

function FinishFlow() {
  const { t } = useTranslation("TagDispenser");
  const location = useLocation();
  const theme: any = useTheme();
  //@ts-ignore
  const userEmail = location.state?.email;
  return (
    <Container theme={theme}>
      <Lottie
        loop
        animationData={HeroBannerLottieSrc}
        play
        style={{ width: 150, height: 150 }}
      />
      <Title variant="h5" sx={{ mb: 2 }}>
        {t("FinishFlow.title")}
      </Title>
      <Typography maxWidth={300} textAlign="center" fontSize="16px">
        <Trans
          t={t}
          components={{ bold: <b /> }}
          values={{ email: userEmail }}
          i18nKey={"FinishFlow.subtitle"}
        />
      </Typography>
    </Container>
  );
}

export default FinishFlow;
