import { APP_URL } from "config";

type Image = Record<string, any>;
type Lottie = Record<string, any>;

export default async function fetchCustomAssets(
  receivedImages: string[],
  receivedLotties: string[],
  prefix: string,
  logo_url: any,
) {
  let lotties: Lottie = {};
  let images: Image = {};

  if (receivedLotties.length > 0) {
    const lottieResponses: Lottie[] = await Promise.all([
      ...receivedLotties.map(async (lottieName) => {
        if (lottieName !== "ready.json") return {} //SOLO TRAEMOS READY.JSON POR AHORA
        try {
          const lottieResponse = await fetch(
            `${APP_URL}/${prefix}/lotties/${lottieName}`,
          );
          const data = await lottieResponse.json()
          return { value: data, name: lottieName };
        } catch (err) {
          console.error("Error loading lotties", err);
          return {};
        }
      }),
    ]);
    lottieResponses.forEach(({ name, value }) => {
      lotties[name] = value;
    });
  }

  if (logo_url !== false) {
    try {
      await imgOnLoad(logo_url);
      images["logo"] = logo_url;
    } catch (err) {
      console.error("Error loading logo", err);
    }
  }

  //IMAGENES, POR AHORA NO USAMOS NINGUNA MAS QUE EL LOGO
  // if (receivedImages.length > 0) {
  //   const imageResponses: Image[] = await Promise.all([
  //     ...receivedImages.map(async (imageName) => {
  //       try {
  //         const imageLink = `${APP_URL}/${prefix}/images/${imageName}`;
  //         await imgOnLoad(imageLink);
  //         return { name: imageName, value: imageLink };
  //       } catch (err) {
  //         console.error("Error loading image", err);
  //         return {};
  //       }
  //     }),
  //   ]);
  //   imageResponses.forEach(({ name, value }) => {
  //     images[name] = value;
  //   });
  // }

  return { lotties, images };
}

function imgOnLoad(src: string) {
  new Promise((resolve, reject) => {
    const img = new Image();
    img.src = src;
    img.onload = resolve;
    img.onerror = reject;
  });
}
