import React from "react";
import { Container, Subtitle, Title } from "../../components";
import email from "./bag_w_code.png";
import CodeInput from "./CodeInput";
import { Button } from "../../components";
import { ArrowForward } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material";

function InsertCode({
  handleSubmit,
  handleDefaultGreeting
}: {
  handleSubmit: (code: string) => void;
  handleDefaultGreeting: () => void;
}) {
  const CODE_LENGTH = 8;
  const [code, setCode] = React.useState("");
  const { t } = useTranslation("TagDispenser");
  const theme: any = useTheme();
  const styles = {
    button: {
      width: "100%",
      textTransform: theme.layout?.buttons?.textTransform || "none",
      height: "56px",
      maxWidth: 300,
      borderRadius: `${theme.shape?.buttons?.borderRadius} !important` || "12px !important",
      fontSize: "18px",
      fontWeight: 700,
    },
    defaultButton: {
      width: "100%",
      maxWidth: 300,
    }
  }
  const handleInputChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!/^[a-zA-Z0-9]{0,8}$/.test(event.target.value)) return;
      setCode(event.target.value.slice(0, CODE_LENGTH));
    },
    []
  );
  const handleCodeSubmit = React.useCallback(() => {
    handleSubmit(code);
  }, [code, handleSubmit]);
  return (
    <Container theme={theme} style={{ backgroundImage: "unset" }}>
      <img
        src={email}
        alt="bag-with-qr-tag"
        height="160px"
        style={{ marginBottom: "20px" }}
      />

      <Title variant="h5">{t("CodeAssociation.InsertCode.title")}</Title>
      <Subtitle sx={{ color: "black", fontSize: "16px" }} gutterBottom>
        {t("CodeAssociation.InsertCode.subtitle")}
      </Subtitle>
      <CodeInput
        sx={{ mb: 2 }}
        onChange={handleInputChange}
        value={code}
        onKeyDown={(e) => e.key === "Enter" && handleCodeSubmit()}
      />
      <Button
        disabled={Boolean(code)}
        sx={{ mb: 2, ...styles.defaultButton }}
        onClick={handleDefaultGreeting} 
        endIcon={<ArrowForward sx={{ mt: "2px" }}
        />}
      >
        {t("CodeAssociation.InsertCode.actionSecondary")}
      </Button>
      <Button
        disabled={code.length !== CODE_LENGTH}
        variant="contained"
        sx={styles.button}
        onClick={handleCodeSubmit}
      >
        {t("CodeAssociation.InsertCode.actionPrimary")}
      </Button>
    </Container>
  );
}

export default InsertCode;
