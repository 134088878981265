import { Box, Checkbox } from "@mui/material";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  terms: {
    fontSize: "14px",
    "& a": {
      textDecoration: "underline",
    },
  },
}));
interface IAcceptanceInfo {
  checked: boolean;
  handleChecked: () => void;
  terms: string;
}
export default function AcceptanceTerms({
  checked,
  handleChecked,
  terms,
}: IAcceptanceInfo) {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      width="100%"
      maxWidth="320px"
      alignItems="flex-start"
      mb={4}
      >
      <Checkbox
        checked={checked}
        onChange={handleChecked}
      />
      <div
        className={classes.terms}
        dangerouslySetInnerHTML={{
          __html: terms,
        }}
      />
    </Box>
  );
}
