import { FC, lazy, Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { LayoutContextProvider } from "context";
import { Main } from "pages";

const Integrations = lazy(() => import("./pages/Integrations"));
const PricingPlans = lazy(() => import("./pages/PricingPlans"));
const PrivacyTerms = lazy(() => import("./pages/PrivacyTerms"));
const SenderPrivacyTerms = lazy(() => import("./pages/SenderPrivacyTerms"));
const ReceiverPrivacyTerms = lazy(() => import("./pages/ReceiverPrivacyTerms"));
const Stores = lazy(() => import("./pages/Stores"));
const DispenserContext = lazy(() => import("./pages/TagDispenser"));
const RedirectShortCode = lazy(() => import("./pages/RedirectShortCode"));

const App: FC = () => {
  return (
    <LayoutContextProvider>
      <Router>
        <Switch>
          <Route exact path="/" component={Main} />
          <Suspense fallback={<></>}>
            <Route exact path="/integrations" component={Integrations} />
            <Route path="/pricing/plans" component={PricingPlans} />
            <Route path="/privacy-terms" component={PrivacyTerms} />
            <Route
              path="/mallplaza/sender-privacy-terms"
              component={SenderPrivacyTerms}
            />
            <Route
              path="/mallplaza/receiver-privacy-terms"
              component={ReceiverPrivacyTerms}
            />
            <Route path="/regala" component={Stores} />
            <Route
              path="/dispenser/:clientID"
              component={DispenserContext}
            />
            <Route path="/short/:short_code" component={RedirectShortCode} />
          </Suspense>
        </Switch>
      </Router>
    </LayoutContextProvider>
  );
};

export default App;
