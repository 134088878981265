import React from "react";
import InsertCode from "./InsertCode";
import SaveEmail from "./SaveEmail";
import { APP_URL, API_SITE_URL } from "config/environment";
import { useParams } from "react-router-dom";
function CodeAssociation() {
  const [code, setCode] = React.useState("");
  const [defaultGreeting, setDefaultGreeting] = React.useState(false);
  const { clientID } = useParams<{ clientID: string }>();

  const handleDefaultGreeting = React.useCallback(() => {
    setDefaultGreeting(!defaultGreeting);
    setCode(" ")
  }, [defaultGreeting]);

  const handleCodeSubmit = React.useCallback(
    (code: string) => setCode(code),
    []
  );
  const handleSaveEmail = React.useCallback(
    async (email: string) => {
      await fetch(new URL("/user", API_SITE_URL).toString(), {
        method: "PUT",
        body: JSON.stringify({
          qrCode:code,
          receiverEmail: email,
        }),
      });
      if (defaultGreeting) {
        const storyURL = new URL(
          "/story/" + clientID?.toLowerCase() + "?d=d",
          APP_URL
        ).toString();
        window.location.href = storyURL;
        return
      }
      const storyURL = new URL(
        "/story/" + code?.toLowerCase(),
        APP_URL
      ).toString();
      window.location.href = storyURL;
    },
    [code, clientID, defaultGreeting]
  );

  if (!code) return <InsertCode handleSubmit={handleCodeSubmit} handleDefaultGreeting={handleDefaultGreeting}/>;
  return <SaveEmail handleSubmit={handleSaveEmail}/>;
}

export default CodeAssociation;
