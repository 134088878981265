import { useHistory, useParams } from "react-router-dom";
import { Container } from "../components";
import { Box, useTheme, Stack, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import senderImage from "./sender.png";
import receiverImage from "./receiver.png";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    width: "100%",
    maxWidth: "500px",
  },
  half: {
    height: "50%",
    overflow: "hidden",
    position: "relative",
  },
  imageContainer: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    maxHeight: "100%",
    width: "100%",
    height: "auto",
    objectFit: "cover",
  },
  button: {
    position: "absolute",
    top: "80%",
    right: "0%",
    transform: "translate(5%, -50%)",
  },
}));

export default function Dispenser() {
  //NOTE this clientID is a hashed client ID using this method -> https://github.com/Storydots/storydots-back/blob/38a8c972d5c1ac7fa2c28a5bcfd92f881dadeb48/TS/layers/nodejs/Utils/hashing/index.ts#L8
  const history = useHistory();
  const { t } = useTranslation("TagDispenser");
  const theme: any = useTheme();
  const { clientID } = useParams<{ clientID: string }>();
  const classes = useStyles();

  const handleSenderButton = () => {
    history.push(`/dispenser/${clientID}`);
  };

  const handleReceiverButton = () => {
    history.push(`/dispenser/${clientID}/code-association`);
  };

  return (
    <Container theme={theme}>
      <Stack className={classes.root}>
        <Box className={classes.half}>
          <Box className={classes.imageContainer}>
            <img
              src={senderImage}
              alt="sender image"
              className={classes.image}
            />
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classes.button}
              onClick={handleSenderButton}
            >
              <div style={{ paddingRight: "5px" }}>{t("RoleSelection.senderButton")}</div>
              <ArrowForwardIcon />
            </Button>
          </Box>
        </Box>
        <Box className={classes.half}>
          <Box className={classes.imageContainer}>
            <img
              src={receiverImage}
              alt="receiver image"
              className={classes.image}
            />
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classes.button}
              onClick={handleReceiverButton}
            >
              <div style={{ paddingRight: "5px" }}>{t("RoleSelection.receiverButton")}</div>
              <ArrowForwardIcon />
            </Button>
          </Box>
        </Box>
      </Stack>
    </Container>
  );
}
