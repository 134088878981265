import React from "react";
import ArrowForward from "@mui/icons-material/ArrowForward";
import {
  TextField,
  Typography,
  Button,
  CircularProgress,
  useTheme,
} from "@mui/material";
import { useTranslation, Trans } from "react-i18next";
import { useParams } from "react-router-dom";
import { API_SITE_URL } from "config";

const isValidEmail = (email: string | undefined | null) => {
  if (!email) return false;
  return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email);
};
type IFormValues = {
  email: string;
};
interface ITagDispenserForm {
  onSubmit: ({ email }: IFormValues) => Promise<void> | void;
}

const TagDispenserForm: React.FC<ITagDispenserForm> = ({ onSubmit }) => {
  const [email, setEmail] = React.useState("");
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleEmailChange = React.useCallback((e) => {
    setEmail(e.target.value);
  }, []);
  const { t } = useTranslation("TagDispenser");
  const { clientID } = useParams<{ clientID: string }>();

  const theme = useTheme();
  const styles = {
    button: {
      justifySelf: "flex-end",
      backgroundColor: theme.palette.custom?.buttons
        ? theme.palette.custom.buttons.main
        : theme.palette.primary?.main,
      width: "100%",
      height: "56px",
      maxWidth: 300,
      borderRadius:
        `${theme.shape?.buttons?.borderRadius} !important` || "12px !important",
      textTransform: theme.layout?.buttons?.textTransform || "none",
      fontSize: "18px",
      fontWeight: "700",
    },
  };

  const fetchTagDispenser = async ({
    email,
    encriptedClientId,
  }: {
    email: string;
    encriptedClientId: string;
  }) => {
    try {
      setError(false);
      setLoading(true);
      const response = await fetch(
        `${API_SITE_URL}/dispenser/${encriptedClientId}?redirect=0`,
        {
          method: "POST",
          body: JSON.stringify({ sender_email: email }),
        }
      );
      if (!response.ok) {
        console.debug(response.statusText);
        throw new Error("Error fetching tag dispenser service >>");
      }
      const redirectURL = await response.text();
      window.localStorage.setItem("storydots-story-url", redirectURL);
      await onSubmit({ email });
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = React.useCallback(async () => {
    await fetchTagDispenser({ email, encriptedClientId: clientID });
  }, [email, onSubmit]);

  return (
    <>
      <Typography mb={2} flexWrap="wrap" maxWidth={300} textAlign="center">
        <Trans t={t} components={{ bold: <b /> }} i18nKey={"Home.form.title"} />
      </Typography>
      <TextField
        sx={{ maxWidth: 300, width: "100%", mb: 2 }}
        InputProps={{ sx: { borderRadius: "12px" } }}
        label={t("Home.form.label")}
        placeholder={t("Home.form.placeholder")}
        error={error}
        helperText={error && t("Home.form.error")}
        onChange={handleEmailChange}
        type="email"
        onKeyDown={(e) => {
          if (e.key === "Enter") handleSubmit();
        }}
      />
      <Button
        fullWidth
        variant="contained"
        sx={styles.button}
        disabled={!isValidEmail(email) || loading}
        type="submit"
        onClick={handleSubmit}
        endIcon={!loading && <ArrowForward />}
      >
        {loading ? (
          <CircularProgress
            sx={{ color: theme.palette.primary.main || "#9E9E9E" }}
            size="32px"
          />
        ) : (
          <>{t("Home.action.primary")}</>
        )}
      </Button>
    </>
  );
};

export default TagDispenserForm;
