import React from "react";
import {
  Button,
  Container,
  Subtitle,
  Title,
} from "pages/TagDispenser/components";
import banner from "./envelope_send.svg";
import { CircularProgress, TextField, InputAdornment, useTheme } from "@mui/material";
import { MailOutline } from "@mui/icons-material";
import AcceptanceTerms from "./TermsAcceptance";
import { useTranslation } from "react-i18next";

function SaveEmail({
  handleSubmit,
}: {
  handleSubmit: (email: string) => Promise<void>;
}) {
  const [email, setEmail] = React.useState<string>("");
  const [sendingEmail, setSendingEmail] = React.useState(false);
  const [acceptedTerms, setAccepterms] = React.useState<boolean>(false);
  const { t } = useTranslation("TagDispenser");
  const theme: any = useTheme();
  const styles = {
    button: {
      width: "100%",
      textTransform: theme.layout?.buttons?.textTransform || "none",
      height: "56px",
      maxWidth: 300,
      borderRadius: `${theme.shape?.buttons?.borderRadius} !important` || "12px !important",
      fontSize: "18px",
      fontWeight: 700,
    }
  }
  const isValidEmail = (email: string) => {
    return /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email);
  };
  const handleTermsAcceptance = React.useCallback(() => {
    setAccepterms((prev) => !prev);
  }, []);

  const handleSendEmail = async () => {
    setSendingEmail(true);
    await handleSubmit(email)
      .catch(console.error)
      .finally(() => setSendingEmail(false));
  };
  return (
    <Container theme={theme} sx={{ backgroundImage: "unset" }}>
      <img
        src={banner}
        alt="envelope-email"
        width="160px"
        style={{ marginBottom: "20px" }}
      />
      <Title variant="h6">{t("CodeAssociation.SaveEmail.title")}</Title>
      <Subtitle sx={{ color: "black", fontSize: "16px", mb: 2 }}>
        {t("CodeAssociation.SaveEmail.subtitle")}
      </Subtitle>
      <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <MailOutline />
            </InputAdornment>
          ),
          style: { borderRadius: "8px", height: "50px" },
        }}
        sx={{ width: "100%", maxWidth: "320px", mb: 4 }}
        type="email"
        placeholder={t("CodeAssociation.SaveEmail.email.placeholder")}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        error={!!email.length && !isValidEmail(email)}
        helperText={t("CodeAssociation.SaveEmail.email.helperText")}
      />
      <AcceptanceTerms
        checked={acceptedTerms}
        handleChecked={handleTermsAcceptance}
        terms={t("CodeAssociation.SaveEmail.acceptanceInfo")}
      />

      <Button
        variant="contained"
        disabled={!isValidEmail(email) || !acceptedTerms || sendingEmail}
        sx={styles.button}
        endIcon={
          sendingEmail && (
            <CircularProgress
              sx={{
                color: (theme) => theme.palette.grey[400],
              }}
              size={24}
            />
          )
        }
        onClick={handleSendEmail}
      >
        {t("CodeAssociation.SaveEmail.action")}
      </Button>
    </Container>
  );
}

export default SaveEmail;
